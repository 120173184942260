import * as React from "react"
import Layout from "../components/layout"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import InlineSVG from "../components/inline-svg"
import Container from "react-bootstrap/Container"
import Hero from "../components/hero"

const Index = ({ data }) => {

  return (
      <Layout container={false} bodyClass='page-home'>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://bhkcrisiscommunications.com",
                "name": "BHK Crisis Communications",
                "logo": "https://bhkcrisiscommunications.com${data.icon.publicURL}",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+33 6 48 69 77 07"
                }
              }
            `}
          </script>
        </Helmet>
        <SEO/>
        <article>
          <Hero
            wide={true}
            image={data.banner}
            className='home-hero'
            collapse={true}
            title='We help you prepare for the unexpected.'
          >

            <p className='lead mt-3'>Crises usually occur when least expected.</p>
            <p className='lead'>Survival requires anticipation plus prompt and effective responses.</p>

            <p className='lead mb-0'>We are uniquely equipped to prepare you for "the unexpected" so that you can protect your reputation, your employees, customers, shareholders and your business.</p>

          </Hero>

          <Container className='page-home'>
            <Row className='text-sm-center my-5' xs={1} md={3}>
              {data.pillars.nodes.map(pillar => (
                <Col className='mb-4 d-flex flex-column' key={pillar.slug}>

                  <Link to={`/${pillar.slug}`}>
                    <h2 className='text-dark'>{pillar.name}</h2>
                  </Link>
                  <Row xs={2} sm={2} md={1} className='flex-grow-1'>
                    <Col xs={9} sm={9} md={{order: 2}}>
                      <p className='lead'>{pillar.description}</p>
                    </Col>
                    <Col xs={3} sm={3} md={{order: 1}}>
                      <InlineSVG svg={pillar.badge.svg} alt={pillar.name} className='pillar-image img-fluid mb-3'/>
                    </Col>
                  </Row>
                  <Link to={`/${pillar.slug}`}>
                    <Button as="span" block>{pillar.buttonText}</Button>
                  </Link>

                </Col>
              ))}
            </Row>

            <Link to="/our-work">
              <h2 className='text-center text-dark pb-2'>Trusted by</h2>
            </Link>

            <Row xs={2} md={3} lg={6} className='align-items-center clients mb-3'>
              {data.clients.nodes.map(client =>
                <Col className='px-4 py-3 text-center' key={client.id}>
                  <InlineSVG svg={client.logo.svg} alt={client.name} className='img-fluid'/>
                </Col>
              )}
            </Row>

            <div className='text-center mb-5'>
              <Link to="/our-work">
                <Button as="span" variant='primary' size='sm'>And many more</Button>
              </Link>
            </div>
          </Container>

        </article>

      </Layout>
  );
}

export default Index;

export const query = graphql`
  query {
    banner: contentfulAsset(contentful_id: {eq: "1HayVXIqWOEcHcXEmMSJXR"}) {
      fluid(quality: 70, maxWidth: 2400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }

    pillars: allContentfulPillar(sort: {fields: index}) {
      nodes {
        id
        name
        description
        slug
        buttonText
        badge {
          svg {
            absolutePath
            content
            dataURI
            relativePath
          }
        }
      }
    }

    clients: allContentfulClient(sort: {fields: name}, limit: 6, filter: {logoColour: {file: {contentType: {eq: "image/svg+xml"}}}, showOnHomepage: {eq: true}}) {
      nodes {
        id
        name
        logo: logoColour {
          svg {
            absolutePath
            content
            dataURI
            relativePath
          }
        }
      }
    }

    icon: file(extension: {eq: "png"}, name: {eq: "icon"}) {
      id
      publicURL
    }

  }
`
